import { inboxConstants, inboxDeleteConstants } from "../constant/constant";
import axios from "../helpers/axios";

export const adminGetEnquiry = (skip) => {
    return async (dispatch) => {
        dispatch({ type: inboxConstants.INBOX_REQUEST });
        const res = await axios.post(`/admin-get-enquiry`,{skip}).catch((err) => {
            console.log(err.response)
            const { message } = err.response.data;
            dispatch({
                type: inboxConstants.INBOX_FAILURE,
                payload: {
                    message,
                },
            });
        })
        if (res.status === 200) {
            const { enquiry } = res.data;
            dispatch({
                type: inboxConstants.INBOX_SUCCESS,
                payload: {
                    enquiry,
                },
            });
        }
    };
};


export const adminDeleteEnquiry = (eid) => {
    return async (dispatch) => {
        dispatch({ type: inboxDeleteConstants.INBOX_DELETE_REQUEST });
        const res = await axios.post('/admin-remove-enquiry', {
            eid
        })
        if (res.status === 200) {
            const { message } = res.data;
            dispatch({
                type: inboxDeleteConstants.INBOX_DELETE_SUCCESS,
                payload: {
                    message,
                },
            });
        }
    };
}

