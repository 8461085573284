import { getBannerConstants, createBannerConstants, deleteBannerConstants } from "../constant/constant";

const initState = {
    message: '',
    banner: []
};

const BannerReducer = (state = initState, action) => {

    switch (action.type) {
        case getBannerConstants.GET_BANNER_SUCCESS:
            state = {
                ...state,
                banner: action.payload.banner,
            }
            break;
        case deleteBannerConstants.DELETE_BANNER_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
            }
            break;
        case createBannerConstants.CREATE_BANNER_SUCCESS:
            state = {
                ...state,
                banner: action.payload.banner,
            }
            break;
        default: {
            state = {
                ...state
            }
        }
    }
    return state;
}

export default BannerReducer