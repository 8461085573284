import React, { useEffect, useState } from 'react'
import AdminSidebar from '../../components/Admin/SideBar/AdminSidebar';
import AdminTopbar from '../../components/Admin/TopBar/AdminTopbar';
import { BiDownload, BiEdit, BiPlus, BiTrash } from 'react-icons/bi';
import Img from '../../assets/cat2.jpg';
// import { AdminAllCategory, AdminDeleteCategoryOrders } from '../../actions/Admin/AdminAction';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { adminDeleteCourse, adminGetCourses } from '../../actions/CourseAction';
import { adminDeleteCategory } from '../../actions/CategoryAction';

const AdminCourses = () => {

  const navigate = useNavigate();
  const [courses, setCourses] = useState(null);
  const allcourses = useSelector(state => state.courses.course);

  const authenticate = sessionStorage.getItem('admin_authenticate');
  useEffect(() => {
    if (!authenticate) {
      navigate('/');
    }
  }, [authenticate])

  const successToast = (msg) => {
    toast(msg, { position: 'top-center' });
  }

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(adminGetCourses(next));
  }, [dispatch])

  useEffect(() => {
    if (allcourses) {
      setCourses(allcourses);
      console.log(allcourses);
    }
  }, [allcourses])


  const deleteCourse = (cid) => {
    dispatch(adminDeleteCourse(cid)).then(() => {
      dispatch(adminGetCourses(next));
    })
  }

  const [next, setNext] = useState(0);

  const paginationNext = (e) => {
    e.preventDefault();
    let nxt = next + 1;
    setNext(nxt);
    dispatch(adminGetCourses(nxt)).then(() => {
    })
  }

  const paginationPrev = (e) => {
    e.preventDefault();
    if (next <= 0) {
      return;
    } else {
      let prev = next - 1;
      setNext(prev);
      dispatch(adminGetCourses(prev)).then(() => {

      })
    }
  }

  // const handleSearch=(name)=>{
  //   if(name == ""){
  //     name=null;
  //   }
  //   dispatch(AdminAllCategory(0,name));
  // }



  return (
    <>
      <div className='flex w-full h-screen'>
        <AdminSidebar name={'courses'} />
        <div className='flex flex-col w-full h-screen '>
          <AdminTopbar />
          <div className='flex flex-col pt-5 pl-5 pr-10'>
            <h1 className='font-dmsans text-lg font-semibold'>Courses</h1>
            <div className='w-full border rounded-lg mt-5 h-20 items-center flex pl-4 pt-4 pb-4 justify-end pr-5'>
              <Link to={'/admin-add-course'}><button className='text-white h-11 hover:scale-110 transition-all duration-300 w-[200px] rounded-md flex justify-center items-center text-sm font-dmsans  bg-darkred'>Add Courses<span className='ml-2'><BiPlus size={15} /></span></button></Link>

            </div>

         
            <div className='flex-col mt-5'>

              <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" class="px-6 py-4">
                        sno.
                      </th>
                      <th scope="col" class="px-6 py-4">
                        Image
                      </th>
                      <th scope="col" class="px-6 py-4">
                        Name
                      </th>
                      <th scope="col" class="px-6 py-4">
                        Category
                      </th>
                      <th scope="col" class="px-6 py-4">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className='text-black'>
                  {courses?.map((course,key)=>(
                    <tr key={key} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                      <td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {key+1}
                      </td>
                      <td class="px-5 p-4 flex  items-center">
                        <div className='w-10 h-10 rounded-full flex mr-2'><img className='w-full h-full rounded-full' src={course?.images[0] || ''} alt="" /></div>
                      </td>
                      <td class="px-6 py-4">
                        {course?.name}
                      </td>
                      <td class="px-6 py-4">
                        {course?.category?.name}
                      </td>
                      {/* <td>1</td> */}
                      <td class="px-4 py-4">
                        <div className='flex'>
                          <span className='mb-2 cursor-pointer hover:scale-110 duration-300 transition-all' onClick={()=>{
                            deleteCourse(course?._id)
                          }}>
                            <BiTrash size={20} color='darkred' />
                          </span>
                        </div>
                      </td>
                    </tr>
                    ))}

                  </tbody>
                </table>
                <nav class="flex items-center justify-between pt-4 pl-3 pr-3 mb-3" aria-label="Table navigation">
                  <span class="text-sm font-normal text-gray-500 dark:text-gray-400">Showing <span class="font-semibold text-gray-900 dark:text-white">1-10</span> of <span class="font-semibold text-gray-900 dark:text-white">20</span></span>
                  <ul class="inline-flex -space-x-px text-sm h-8">
                    <li
                    onClick={paginationPrev}
                    >
                      <p class=" cursor-pointer flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Previous</p>
                    </li>
                    <li
                     onClick={paginationNext}
                    >
                      <p class=" cursor-pointer flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Next</p>
                    </li>
                  </ul>
                </nav>
              </div>

            </div>
          </div>
        </div>
      </div >
      <ToastContainer />
    </>
  )
}

export default AdminCourses