import { combineReducers } from "redux";
import AdminReducer from "./Admin/AdminReducer";
import InboxReducer from "./InboxReducer";
import BannerReducer from "./BannerReducer";
import CategoryReducer from "./CategoryReducer"
import CourseReducer from "./CourseReducer";
import EventReducer from "./EventReducer";
import CustomerReducer from "./CustomerReducer";
import AchievmentReducer from "./AchievmentReducer";

const rootReducer = combineReducers({
    admin:AdminReducer,
    inbox:InboxReducer,
    banner:BannerReducer,
    category:CategoryReducer,
    courses:CourseReducer,
    events:EventReducer,
    users:CustomerReducer,
    achievments:AchievmentReducer
});

export default rootReducer;