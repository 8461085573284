import { createEventConstants, deleteEventConstants, getEventConstants } from "../constant/constant";

const initState = {
    message: '',
    events: []
};

const EventReducer = (state = initState, action) => {

    switch (action.type) {
        case getEventConstants.GET_EVENT_SUCCESS:
            state = {
                ...state,
                events: action.payload.events,
            }
            break;
        case deleteEventConstants.DELETE_EVENT_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
            }
            break;
        case createEventConstants.CREATE_EVENT_SUCCESS:
            state = {
                ...state,
                // message:action.payload.message
            }
            break;
        default: {
            state = {
                ...state
            }
        }
    }
    return state;
}

export default EventReducer