import React, { useEffect } from 'react'
import { useState } from 'react'
import AdminSidebar from '../../components/Admin/SideBar/AdminSidebar';
import AdminTopbar from '../../components/Admin/TopBar/AdminTopbar';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { adminAddBanner } from '../../actions/BannerAction';
// import { AdminAllCategoryParent, AdminCreateCategory } from '../../actions/Admin/AdminAction';

const AddEvents = () => {

    const [hamburger, setHamburger] = useState(true);
    const [color, setColor] = useState("#000000");
    const [text, setText] = useState(null);
    const [tagline, setTagline] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector(state => state.admin);

    const authenticate = sessionStorage.getItem('admin_authenticate');
    useEffect(() => {
        if (!authenticate) {
            navigate('/');
        }
    }, [authenticate])

    useEffect(()=>{
        setTagline('Top Programs');
    },[])


    const handleAddBanner=(e)=>{
        e.preventDefault();
        dispatch(adminAddBanner(text,color,tagline)).then(()=>{
            navigate('/admin-banner');
        })
    }

    return (
        <>
            <div className='flex w-full h-screen'>
                {hamburger &&
                    <AdminSidebar name={'products'} />
                }
                <div className='flex flex-col w-full h-screen'>
                    <AdminTopbar />
                    <div className='w-full h-full pl-10 pt-5 pr-5'>
                        <div className='flex justify-between pr-10'>
                            <h1 className='font-dmsans text-lg'>Add Banner</h1>
                        </div>
                        <form
                        onSubmit={handleAddBanner}
                        >
                            <div className='mt-4'>
                                <div className='pr-[500px] mb-20'>
                                    <div className='items-center justify-between flex mt-10 text-black font-dmsans'>
                                        <p className='mr-10'>Text</p>
                                        <input onChange={(e) => { setText(e.target.value) }} type="text" placeholder='Banner Text' className='rounded-lg  text-sm pl-3 bg-[#1a1a1d0d] border-[#1a1a1d15] h-12 w-[500px]' required />
                                    </div>
                                    <div className='items-center justify-between flex mt-10 text-black font-dmsans'>
                                        <p className='mr-10'>Tagline</p>
                                        <select className='rounded-lg  text-sm pl-3 bg-[#1a1a1d0d] border-[#1a1a1d15] h-12 w-[500px]' onChange={(e) => { setTagline(e.target.value) }} >
                                            <option defaultChecked={true} value={"Top Programs"}>Top Programs</option>
                                            <option value={"Current Events"}>Current Events</option>
                                        </select>
                                    </div>
                                    <div className='items-center justify-between flex mt-10 text-black font-dmsans'>
                                        <label htmlFor="colorPicker">Select a color:</label>
                                        <input
                                            type="color"
                                            id="colorPicker"
                                            value={color}
                                            onChange={(e)=>{setColor(e.target.value)}}
                                        />
                                        <p>Selected Color: {color}</p>
                                    </div>
                                    <div className='items-center flex mt-10 text-black font-dmsans'>
                                        <button className='w-[100px] h-10 flex justify-center items-center border rounded-md shadow text-sm font-dmsans text-darkred' onClick={() => { navigate('/admin-banner') }}>Cancel</button>
                                        <button className='w-[100px] h-10 flex justify-center items-center border rounded-md shadow text-sm font-dmsans bg-darkred text-white ml-5' type='Submit'>Submit</button>
                                    </div>
                                    <br /><br />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddEvents