import {getEventConstants,createEventConstants,deleteEventConstants} from "../constant/constant";
import axios from "../helpers/axios";

export const adminCreateEvent = (eventObj) => {
    const formData = new FormData();
    for (const image of eventObj["images"]) {
      formData.append("images", image);
    }
    for (const key in eventObj) {
      if (eventObj[key] !== undefined && key !== "images") {
        formData.append(key, eventObj[key]);
      }
    }
    return async (dispatch) => {
      dispatch({ type: createEventConstants.CREATE_EVENT_REQUEST });
      const res = await axios.post(`/admin-add-event`, formData)
      if (res.status === 200) {
        const { event } = res.data;
        dispatch({
          type: createEventConstants.CREATE_EVENT_SUCCESS,
          payload: {
            event,
            message:"Event Created"
          },
        });
      }
    };
  
  };

export const adminGetEvent = (skip) => {
    return async (dispatch) => {
        dispatch({ type: getEventConstants.GET_EVENT_REQUEST});
        const res = await axios.post(`/admin-get-event`,{skip}).catch((err) => {
            console.log(err.response)
            const { message } = err.response.data;
            dispatch({
                type: getEventConstants.GET_EVENT_FAILURE,
                payload: {
                    message,
                },
            });
        })
        if (res.status === 200) {
            const { events} = res.data;
            dispatch({
                type: getEventConstants.GET_EVENT_SUCCESS,
                payload: {
                    events,
                },
            });
        }
    };
};


export const adminDeleteEvent = (eid) => {
    return async (dispatch) => {
        dispatch({ type: deleteEventConstants.DELETE_EVENT_REQUEST });
        const res = await axios.post('/admin-remove-event', {
            eid
        })
        if (res.status === 200) {
            const { message } = res.data;
            dispatch({
                type: deleteEventConstants.DELETE_EVENT_SUCCESS,
                payload: {
                    message,
                },
            });
        }
    };
}

