import React, { useEffect } from 'react'
import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import AdminLogin from './pages/Login/AdminLogin';
import AdminDashboard from './pages/AdminPanel/AdminDashboard';
import AdminEvents from './pages/AdminPanel/AdminEvents';
import AddEvents from './pages/AdminPanel/AddEvents';
import AddCourses from './pages/AdminPanel/AddCourses';
import AddBanner from './pages/AdminPanel/AddBanner';
import AdminInbox from './pages/AdminPanel/AdminInbox';
import AdminCourses from './pages/AdminPanel/AdminCourses';
import AdminBanner from './pages/AdminPanel/AdminBanner';
import AdminCustomers from './pages/AdminPanel/AdminCustomers';
import AdminAchievment from './pages/AdminPanel/AdminAchievment'
import AddAchievment from './pages/AdminPanel/AddAchievment';


function App() {
  useEffect(()=>{
    sessionStorage.setItem("admin_authenticate",true);
  },[])
  return (
    <div className="App">
      <Routes>
        <Route exact path={"/"} element={<AdminLogin />} />
        <Route exact path={"/dashboard"} element={<AdminDashboard />} />
        <Route exact path={"/admin-events"} element={<AdminEvents />} />
        <Route exact path={"/admin-achievement"} element={<AdminAchievment />} />
        <Route exact path={"/admin-inbox"} element={<AdminInbox />} />
        <Route exact path={"/admin-users"} element={<AdminCustomers />} />
        <Route exact path={"/admin-banner"} element={<AdminBanner />} />
        <Route exact path={"/admin-dashboard"} element={<AdminDashboard/>} />
        <Route exact path={"/admin-courses"} element={<AdminCourses />} />
        <Route exact path={"/admin-add-event"} element={<AddEvents />} />
        <Route exact path={"/admin-add-achievement"} element={<AddAchievment />} />
        <Route exact path={"/admin-add-course"} element={<AddCourses/>} />
        <Route exact path={"/admin-add-banner"} element={<AddBanner/>} />
        {/* <Route exact path={"/admin-add-achievement"} element={<AddAchievements/>} /> */}
        {/* <Route exact path={"/admin-achievements"} element={<AdminAchievement/>} /> */}
      </Routes>
    </div>
  );
}

export default App;
