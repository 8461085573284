import React from 'react'
import { ColorRing, } from 'react-loader-spinner';

const Spinner = () => {
    return (
        <>
        <ColorRing
            height={50}
        />
        </>
    )
}

export default Spinner;
