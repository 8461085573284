import { deleteUser, getAllUsersConstants } from "../constant/constant";

const initState = {
    message: '',
    users: []
};

const CustomerReducer = (state = initState, action) => {

    switch (action.type) {
        case getAllUsersConstants.GET_USERS_SUCCESS:
            state = {
                ...state,
                users: action.payload.users,
            }
            break;
        case deleteUser.DELETE_USER_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
            }
            break;
        default: {
            state = {
                ...state
            }
        }
    }
    return state;
}

export default CustomerReducer