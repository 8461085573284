import { createCourseConstants, deleteCourseConstants, getCourseConstants } from "../constant/constant";
import axios from "../helpers/axios";

export const adminCreateCourse = (eventObj) => {


  const formData = new FormData();
  formData.append("name", eventObj.name);
  formData.append("category", eventObj.category);
  formData.append("courseContent", eventObj.courseContent);

  for (const image of eventObj.images) {
    formData.append('images', image);
  }
  formData.append("pdf", eventObj.pdf);

  return async (dispatch) => {
    dispatch({ type: createCourseConstants.CREATE_COURSE_REQUEST });
    const res = await axios.post(`/admin-create-course`, formData)
    if (res.status === 200) {
      const { course } = res.data;
      dispatch({
        type: createCourseConstants.CREATE_COURSE_SUCCESS,
        payload: {
          course,
          message: "Course Created"
        },
      });
    }
  };

};

export const adminGetCourses = (skip) => {
  return async (dispatch) => {
    dispatch({ type: getCourseConstants.GET_COURSE_REQUEST });
    const res = await axios.post(`/admin-get-course`, { skip }).catch((err) => {
      console.log(err.response)
      const { message } = err.response.data;
      dispatch({
        type: getCourseConstants.GET_COURSE_FAILURE,
        payload: {
          message,
        },
      });
    })
    if (res.status === 200) {
      const { courses } = res.data;
      dispatch({
        type: getCourseConstants.GET_COURSE_SUCCESS,
        payload: {
          courses,
        },
      });
    }
  };
};


export const adminDeleteCourse = (cid) => {
  return async (dispatch) => {
    dispatch({ type: deleteCourseConstants.DELETE_COURSE_REQUEST });
    const res = await axios.post('/admin-remove-course', {
      cid
    })
    if (res.status === 200) {
      const { message } = res.data;
      dispatch({
        type: deleteCourseConstants.DELETE_COURSE_SUCCESS,
        payload: {
          message,
        },
      });
    }
  };
}

