export const userLoginConstants={
    LOGIN_REQUEST:'LOGIN_REQUEST',
    LOGIN_FAILURE:'LOGIN_FAILURE',
    LOGIN_SUCCESS:'LOGIN_SUCCESS',
    LOGOUT_REQUEST:'LOGOUT_REQUEST',
}
export const userUpdateConstants = {
    UPDATE_REQUEST:'UPDATE_REQUEST',
    UPDATE_FAILURE:'UPDATE_FAILURE',
    UPDATE_SUCCESS:'UPDATE_SUCCESS',  
}
export const emailConstants = {
    EMAIL_REQUEST:'EMAIL_REQUEST',
    EMAIL_FAILURE:'EMAIL_FAILURE',
    EMAIL_SUCCESS:'EMAIL_SUCCESS',  
}
export const otpConstants = {
    OTP_REQUEST:'OTP_REQUEST',
    OTP_FAILURE:'OTP_FAILURE',
    OTP_SUCCESS:'OTP_SUCCESS',  
}

export const getAllUsersConstants = {
    GET_USERS_REQUEST:'GET_USERS_REQUEST',
    GET_USERS_FAILURE:'GET_USERS_FAILURE',
    GET_USERS_SUCCESS:'GET_USERS_SUCCESS',  
}

export const deleteUser = {
    DELETE_USER_REQUEST:'DELETE_USER_REQUEST',
    DELETE_USER_FAILURE:'DELETE_USER_FAILURE',
    DELETE_USER_SUCCESS:'DELETE_USER_SUCCESS',  
}



export const inboxConstants = {
    INBOX_REQUEST:'INBOX_REQUEST',
    INBOX_FAILURE:'INBOX_FAILURE',
    INBOX_SUCCESS:'INBOX_SUCCESS',  
}
export const inboxDeleteConstants = {
    INBOX_DELETE_REQUEST:'INBOX_DELETE_REQUEST',
    INBOX_DELETE_FAILURE:'INBOX_DELETE_FAILURE',
    INBOX_DELETE_SUCCESS:'INBOX_DELETE_SUCCESS',  
}


export const createBannerConstants = {
    CREATE_BANNER_REQUEST:'CREATE_BANNER_REQUEST',
    CREATE_BANNER_FAILURE:'CREATE_BANNER_FAILURE',
    CREATE_BANNER_SUCCESS:'CREATE_BANNER_SUCCESS',  
}
export const deleteBannerConstants = {
    DELETE_BANNER_REQUEST:'DELETE_BANNER_REQUEST',
    DELETE_BANNER_FAILURE:'DELETE_BANNER_FAILURE',
    DELETE_BANNER_SUCCESS:'DELETE_BANNER_SUCCESS',  
}
export const getBannerConstants = {
    GET_BANNER_REQUEST:'GET_BANNER_REQUEST',
    GET_BANNER_FAILURE:'GET_BANNER_FAILURE',
    GET_BANNER_SUCCESS:'GET_BANNER_SUCCESS',  
}


export const createEventConstants = {
    CREATE_EVENT_REQUEST:'CREATE_EVENT_REQUEST',
    CREATE_EVENT_FAILURE:'CREATE_EVENT_FAILURE',
    CREATE_EVENT_SUCCESS:'CREATE_EVENT_SUCCESS',  
}
export const deleteEventConstants = {
    DELETE_EVENT_REQUEST:'DELETE_EVENT_REQUEST',
    DELETE_EVENT_FAILURE:'DELETE_EVENT_FAILURE',
    DELETE_EVENT_SUCCESS:'DELETE_EVENT_SUCCESS',  
}
export const getEventConstants = {
    GET_EVENT_REQUEST:'GET_EVENT_REQUEST',
    GET_EVENT_FAILURE:'GET_EVENT_FAILURE',
    GET_EVENT_SUCCESS:'GET_EVENT_SUCCESS',  
}


export const createAchievmentsConstants = {
    CREATE_ACHIEVMENTS_REQUEST:'CREATE_ACHIEVMENTS_REQUEST',
    CREATE_ACHIEVMENTS_FAILURE:'CREATE_ACHIEVMENTS_FAILURE',
    CREATE_ACHIEVMENTS_SUCCESS:'CREATE_ACHIEVMENTS_SUCCESS', 
}
export const deleteAchievmentsConstants = {
    DELETE_ACHIEVMENTS_REQUEST:'DELETE_ACHIEVMENTS_REQUEST',
    DELETE_ACHIEVMENTS_FAILURE:'DELETE_ACHIEVMENTS_FAILURE',
    DELETE_ACHIEVMENTS_SUCCESS:'DELETE_ACHIEVMENTS_SUCCESS',  
}
export const getAchievmentsConstants = {
    GET_ACHIEVMENTS_REQUEST:'GET_ACHIEVMENTS_REQUEST',
    GET_ACHIEVMENTS_FAILURE:'GET_ACHIEVMENTS_FAILURE',
    GET_ACHIEVMENTS_SUCCESS:'GET_ACHIEVMENTS_SUCCESS',  
}


export const createCourseConstants = {
    CREATE_COURSE_REQUEST:'CREATE_COURSE_REQUEST',
    CREATE_COURSE_FAILURE:'CREATE_COURSE_FAILURE',
    CREATE_COURSE_SUCCESS:'CREATE_COURSE_SUCCESS',  
}
export const deleteCourseConstants = {
    DELETE_COURSE_REQUEST:'DELETE_COURSE_REQUEST',
    DELETE_COURSE_FAILURE:'DELETE_COURSE_FAILURE',
    DELETE_COURSE_SUCCESS:'DELETE_COURSE_SUCCESS',  
}
export const getCourseConstants = {
    GET_COURSE_REQUEST:'GET_COURSE_REQUEST',
    GET_COURSE_FAILURE:'GET_COURSE_FAILURE',
    GET_COURSE_SUCCESS:'GET_COURSE_SUCCESS',  
}


export const deleteCategoryConstants = {
    DELETE_CATEGORY_REQUEST:'DELETE_CATEGORY_REQUEST',
    DELETE_CATEGORY_FAILURE:'DELETE_CATEGORY_FAILURE',
    DELETE_CATEGORY_SUCCESS:'DELETE_CATEGORY_SUCCESS',  
}
export const getCategoryConstants = {
    GET_CATEGORY_REQUEST:'GET_CATEGORY_REQUEST',
    GET_CATEGORY_FAILURE:'GET_CATEGORY_FAILURE',
    GET_CATEGORY_SUCCESS:'GET_CATEGORY_SUCCESS',  
}
export const createAchievementConstants = {
    CREATE_ACHIEVEMENT_REQUEST: 'CREATE_ACHIEVEMENT_REQUEST',
    CREATE_ACHIEVEMENT_FAILURE: 'CREATE_ACHIEVEMENT_FAILURE',
    CREATE_ACHIEVEMENT_SUCCESS: 'CREATE_ACHIEVEMENT_SUCCESS',
};

export const getAchievementConstants = {
    GET_ACHIEVEMENT_REQUEST: 'GET_ACHIEVEMENT_REQUEST',
    GET_ACHIEVEMENT_FAILURE: 'GET_ACHIEVEMENT_FAILURE',
    GET_ACHIEVEMENT_SUCCESS: 'GET_ACHIEVEMENT_SUCCESS',
};

export const deleteAchievementConstants = {
    DELETE_ACHIEVEMENT_REQUEST: 'DELETE_ACHIEVEMENT_REQUEST',
    DELETE_ACHIEVEMENT_FAILURE: 'DELETE_ACHIEVEMENT_FAILURE',
    DELETE_ACHIEVEMENT_SUCCESS: 'DELETE_ACHIEVEMENT_SUCCESS',
};
