import { createBannerConstants, deleteBannerConstants, getBannerConstants } from "../constant/constant";
import axios from "../helpers/axios";

export const adminAddBanner = (text,color,tagline) => {
    return async (dispatch) => {
        dispatch({ type: createBannerConstants.CREATE_BANNER_REQUEST });
        const res = await axios.post(`/admin-add-banner`,{
            text,
            tagline,
            color
        }).catch((err) => {
            console.log(err.response)
            const { message } = err.response.data;
            dispatch({
                type: createBannerConstants.CREATE_BANNER_FAILURE,
                payload: {
                    message,
                },
            });
        })
        if (res.status === 200) {
            const { banner } = res.data;
            dispatch({
                type: createBannerConstants.CREATE_BANNER_SUCCESS,
                payload: {
                    banner,
                },
            });
        }
    };
};

export const adminGetBanner = () => {
    return async (dispatch) => {
        dispatch({ type: getBannerConstants.GET_BANNER_REQUEST });
        const res = await axios.post(`/admin-get-banner`).catch((err) => {
            console.log(err.response)
            const { message } = err.response.data;
            dispatch({
                type: getBannerConstants.GET_BANNER_FAILURE,
                payload: {
                    message,
                },
            });
        })
        if (res.status === 200) {
            const { banner } = res.data;
            dispatch({
                type: getBannerConstants.GET_BANNER_SUCCESS,
                payload: {
                    banner,
                },
            });
        }
    };
};


export const adminDeleteBanner = (bid) => {
    return async (dispatch) => {
        dispatch({ type: deleteBannerConstants.DELETE_BANNER_REQUEST });
        const res = await axios.post('/admin-remove-banner', {
            bid
        })
        if (res.status === 200) {
            const { message } = res.data;
            dispatch({
                type: deleteBannerConstants.DELETE_BANNER_SUCCESS,
                payload: {
                    message,
                },
            });
        }
    };
}

