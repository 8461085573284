import { createAchievmentsConstants, deleteAchievmentsConstants, getAchievmentsConstants } from "../constant/constant";

const initState = {
    message: '',
    achievment: []
};

const AchievmentReducer = (state = initState, action) => {

    switch (action.type) {
        case getAchievmentsConstants.GET_ACHIEVMENTS_SUCCESS:
            state = {
                ...state,
                achievment: action.payload.achievment,
            }
            break;
        case deleteAchievmentsConstants.DELETE_ACHIEVMENTS_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
            }
            break;
        case createAchievmentsConstants.CREATE_ACHIEVMENTS_SUCCESS:
            state = {
                ...state,
                // message:action.payload.message
            }
            break;
        default: {
            state = {
                ...state
            }
        }
    }
    return state;
}

export default AchievmentReducer