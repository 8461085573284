import {
    deleteUser,
    getAllUsersConstants,
    userLoginConstants,
} from "../constant/constant";
import axios from "../helpers/axios";

export const adminLogin = (user) => {
    return async (dispatch) => {
        dispatch({ type: userLoginConstants.LOGIN_REQUEST });
        const res = await axios.post(`/login`, {
            ...user,
        }).catch((err) => {
            console.log(err.response)
            const { message } = err.response.data;
            dispatch({
                type: userLoginConstants.LOGIN_FAILURE,
                payload: {
                    message,
                },
            });
        })
        if (res.status === 200) {
            const { user } = res.data;
            sessionStorage.setItem('admin_id', user?._id);
            sessionStorage.setItem('admin_email', user?.email);
            sessionStorage.setItem('admin_authenticate', true);
            dispatch({
                type: userLoginConstants.LOGIN_SUCCESS,
                payload: {
                    user,
                },
            });
        }
    };
};


export const adminGetUsers = (skip) => {
    return async (dispatch) => {
        dispatch({ type: getAllUsersConstants.GET_USERS_REQUEST});
        const res = await axios.post(`/get-all-user`,{skip}).catch((err) => {
            console.log(err.response)
            const { message } = err.response.data;
            dispatch({
                type: getAllUsersConstants.GET_USERS_FAILURE,
                payload: {
                    message,
                },
            });
        })
        if (res.status === 200) {
            const { users } = res.data;
            dispatch({
                type: getAllUsersConstants.GET_USERS_SUCCESS,
                payload: {
                    users,
                },
            });
        }
    };
};


export const adminDeleteUsers = (uid) => {
    return async (dispatch) => {
        dispatch({ type: deleteUser.DELETE_USER_REQUEST });
        const res = await axios.post('/delete-user', {
            uid
        })
        if (res.status === 200) {
            const { message } = res.data;
            dispatch({
                type: deleteUser.DELETE_USER_SUCCESS,
                payload: {
                    message,
                },
            });
        }
    };
}

