import React, { useEffect, useState } from 'react';
import Logo from '../../assets/mandala.jpg';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { adminLogin } from '../../actions/UserAction';
import Spinner from '../../components/Spinner/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FiEyeOff } from 'react-icons/fi';
import { api } from '../../helpers/baseUrl';
import { BiMenu, BiX } from 'react-icons/bi';
import LoginVector from '../../assets/login.png'
import './Login.css';

const AdminLogin = () => {

  
  const auth = useSelector(state => state.admin);
  useEffect(() => {
    if (auth?.authenticate || sessionStorage.getItem('admin_authenticate')) {
      navigate('/dashboard');
    }
  }, [])
  const successToast = () => {
    toast('Login Successfull', { position: toast.POSITION.TOP_CENTER })
  }

  const errorToast = (err) => {
    toast(`${err}`, { position: toast.POSITION.TOP_CENTER })
  }

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (auth?.authenticate) {
      navigate('/');
    }
  }, [auth?.authenticate, navigate])


  const loginUserWithEmailPass = (e) => {
    setLoading(true);
    e.preventDefault();

    if(email && password){
      dispatch(adminLogin({email,password})).then(()=>{
        successToast();
        navigate('/dashboard');
      })
    }

  }


  return (
    <div className='flex flex-col bg-white'>
      <div className='w-full h-16 text-lg items-center flex text-white  pl-5 bg-[#1a1a1d] font-dmsans'>
        Argham Knowledge Services
      </div>
      <div className='w-full h-screen flex justify-center items-start mt-[-60px]'>

        <div className='flex sm:w-1/2 w-full h-full  justify-center items-center scale-90 lg:scale-100'>
          <div className=' w-[370px] sm:w-[450px] h-[370px]  rounded-md '>
            <form action="" onSubmit={loginUserWithEmailPass}>
              <div className='text-pink w-full h-14 text-xl flex items-center font-semibold'>
              </div>
              <div className='pl-10 pr-10 pt-6'>
                <div className='flex flex-col items-start  w-full '>
                  <span className='font-dmsans text-xl font-semibold'>Welcome!</span>
                  <span className='text'>Please login to your account</span>
                </div>
                <label className='text-sm font-dmsans text-left w-full flex text-white' htmlFor="">Email Address</label>
                <input type="text" className='bg-[#d6d5d5] w-full h-11 text-sm text-gray pl-5 border-none ' placeholder='Email Address' onChange={(e) => { setEmail(e.target.value) }} required />
                <label className='text-sm font-dmsans text-left w-full flex text-white' htmlFor="">Password</label>
                <input type="password" className=' bg-[#d6d5d5]  border-none  w-full h-11 text-sm text-gray pl-5 ' placeholder='Password' onChange={(e) => { setPassword(e.target.value) }} required />
                <button className='w-full h-11 bg-[#ffaf03] font-dmsans uppercase text-[#ffffff] mt-5 flex items-center justify-center' type='submit'>{loading ? <Spinner /> : `Sign In`}</button>
              </div>
            </form>
          </div>
        </div>

          <div className='flex justify-center items-center h-full'>
            <div className='w-[600px]'><img  className='h-full w-full ' src={LoginVector} alt="" /></div>
          </div>

      </div>
      <ToastContainer />
    </div>
  )
}

export default AdminLogin;