import { createAchievmentsConstants, deleteAchievmentsConstants, getAchievmentsConstants } from "../constant/constant";
import axios from "../helpers/axios";

export const adminCreateAchievment = (eventObj) => {
    const formData = new FormData();
    for (const image of eventObj["images"]) {
      formData.append("images", image);
    }
    for (const key in eventObj) {
      if (eventObj[key] !== undefined && key !== "images") {
        formData.append(key, eventObj[key]);
      }
    }
    return async (dispatch) => {
      dispatch({ type: createAchievmentsConstants.CREATE_ACHIEVMENTS_REQUEST });
      const res = await axios.post(`/admin-add-achievment`, formData)
      if (res.status === 200) {
        const { event } = res.data;
        dispatch({
          type: createAchievmentsConstants.CREATE_ACHIEVMENTS_SUCCESS,
          payload: {
            achievment:event,
            message:"Achievment Created"
          },
        });
      }
    };
  
  };

export const adminGetAchievment = (skip) => {
    return async (dispatch) => {
        dispatch({ type: getAchievmentsConstants.GET_ACHIEVMENTS_REQUEST });
        const res = await axios.post(`/admin-get-achievment`,{skip}).catch((err) => {
            console.log(err.response)
            const { message } = err.response.data;
            dispatch({
                type: getAchievmentsConstants.GET_ACHIEVMENTS_FAILURE,
                payload: {
                    message,
                },
            });
        })
        if (res.status === 200) {
            const { events} = res.data;
            dispatch({
                type:getAchievmentsConstants.GET_ACHIEVMENTS_SUCCESS,
                payload: {
                    achievment:events,
                },
            });
        }
    };
};


export const adminDeleteAchievment = (aid) => {
    return async (dispatch) => {
        dispatch({ type:deleteAchievmentsConstants.DELETE_ACHIEVMENTS_REQUEST });
        const res = await axios.post('/admin-remove-achievment', {
            aid
        })
        if (res.status === 200) {
            const { message } = res.data;
            dispatch({
                type: deleteAchievmentsConstants.DELETE_ACHIEVMENTS_SUCCESS,
                payload: {
                    message,
                },
            });
        }
    };
}

