import React, { useEffect, useState } from 'react'
import AdminSidebar from '../../components/Admin/SideBar/AdminSidebar';
import AdminTopbar from '../../components/Admin/TopBar/AdminTopbar';
import { BiDownload, BiEdit, BiPlus, BiTrash } from 'react-icons/bi';
import Img from '../../assets/cat2.jpg';
// import { AdminAllCategory, AdminDeleteCategoryOrders } from '../../actions/Admin/AdminAction';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { adminDeleteEnquiry, adminGetEnquiry } from '../../actions/InboxAction';
import { adminDeleteUsers, adminGetUsers } from '../../actions/UserAction';

const AdminCustomers = () => {

  const navigate = useNavigate();
  const [users, setUsers] = useState(null);
  const allusers = useSelector(state => state.users.users);
  const [next, setNext] = useState(0);

  const authenticate = sessionStorage.getItem('admin_authenticate');
  useEffect(() => {
    if (!authenticate) {
      navigate('/');
    }
  }, [authenticate])

  const successToast = (msg) => {
    toast(msg, { position: 'top-center' });
  }

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(adminGetUsers(next));
  }, [dispatch])

  useEffect(() => {
    if (allusers) {
      setUsers(allusers);
    }
  }, [allusers])


  const deleteUser = (uid) => {
    dispatch(adminDeleteUsers(uid)).then(() => {
      dispatch(adminGetUsers(next));
    })
  }


  const paginationNext = (e) => {
    e.preventDefault();
    let nxt = next + 1;
    setNext(nxt);
    dispatch(adminGetUsers(nxt)).then(() => {
    })
  }

  const paginationPrev = (e) => {
    e.preventDefault();
    if (next <= 0) {
      return;
    } else {
      let prev = next - 1;
      setNext(prev);
      dispatch(adminGetUsers(prev)).then(() => {
      })
    }
  }

  // const handleSearch=(name)=>{
  //   if(name == ""){
  //     name=null;
  //   }
  //   dispatch(adminGetEnquiry(0,name));
  // }



  return (
    <>
      <div className='flex w-full h-screen'>
        <AdminSidebar name={'users'} />
        <div className='flex flex-col w-full h-screen '>
          <AdminTopbar />
          <div className='flex flex-col pt-5 pl-5 pr-10'>
            <h1 className='font-dmsans text-lg font-semibold'>Customers</h1>

            <div className='w-full border rounded-lg mt-5 h-20 items-center flex pl-4 pt-4 pb-4 justify-between pr-5'>
            </div>

            <div className='flex-col mt-5'>

              <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" class="px-6 py-4">
                        sno.
                      </th>
                      <th scope="col" class="px-6 py-4">
                        Name
                      </th>
                      <th scope="col" class="px-6 py-4">
                        Email
                      </th>
                      <th scope="col" class="px-6 py-4">
                        Mobile
                      </th>
                      <th scope="col" class="px-6 py-4">
                        Message
                      </th>
                      <th scope="col" class="px-6 py-4">
                        Usertype
                      </th>
                      <th scope="col" class="px-6 py-4">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className='text-black'>
                    {users?.map((user, key) => (
                      
                      !user?.isAdmin && <tr key={key} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          {key+1}
                        </td>
                        <td class="px-6 py-4">
                          {user?.name}
                        </td>
                        <td class="px-6 py-4">
                          {user?.email}
                        </td>
                        <td class="px-6 py-4">
                          {user?.mobile}
                        </td>
                        <td class="px-6 py-4">
                          {user?.message}
                        </td>
                        <td class="px-6 py-4">
                          {user?.usertype}
                        </td>
                        {/* <td>1</td> */}
                        <td class="px-4 py-4">
                          <div className='flex'>
                            <span className='mb-2 cursor-pointer hover:scale-110 duration-300 transition-all' onClick={() => {
                              successToast("Deleting Customer Please Wait")
                              deleteUser(user?._id);
                            }}>
                              <BiTrash size={20} color='darkred' />
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))
                    }

                  </tbody>
                </table>
                <nav class="flex items-center justify-between pt-4 pl-3 pr-3 mb-3" aria-label="Table navigation">
                  <span class="text-sm font-normal text-gray-500 dark:text-gray-400"></span>
                  <ul class="inline-flex -space-x-px text-sm h-8">
                    <li
                      onClick={paginationPrev}
                    >
                      <p class=" cursor-pointer flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Previous</p>
                    </li>
                    <li
                      onClick={paginationNext}
                    >
                      <p class=" cursor-pointer flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Next</p>
                    </li>
                  </ul>
                </nav>
              </div>

            </div>
          </div>
        </div>
      </div >
      <ToastContainer />
    </>
  )
}

export default AdminCustomers