// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topbar{
    min-height: 60px!important;
    max-height: 60px;
}`, "",{"version":3,"sources":["webpack://./src/components/Admin/TopBar/AdminTopbar.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,gBAAgB;AACpB","sourcesContent":[".topbar{\n    min-height: 60px!important;\n    max-height: 60px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
