import {createCourseConstants, deleteCourseConstants, getCourseConstants } from "../constant/constant";

const initState = {
    message: '',
    course: []
};

const CourseReducer = (state = initState, action) => {

    switch (action.type) {
        case getCourseConstants.GET_COURSE_SUCCESS:
            state = {
                ...state,
                course: action.payload.courses,
            }
            break;
        case deleteCourseConstants.DELETE_COURSE_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
            }
            break;
        case createCourseConstants.CREATE_COURSE_SUCCESS:
            state = {
                ...state,
                message:action.payload.message
            }
            break;
        default: {
            state = {
                ...state
            }
        }
    }
    return state;
}

export default CourseReducer