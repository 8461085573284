import { deleteCategoryConstants, getCategoryConstants } from "../constant/constant";

const initState = {
    message: '',
    category: []
};

const CategoryReducer = (state = initState, action) => {

    switch (action.type) {
        case getCategoryConstants.GET_CATEGORY_SUCCESS:
            state = {
                ...state,
                category: action.payload.category,
            }
            break;
        case deleteCategoryConstants.DELETE_CATEGORY_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
            }
            break;
        default: {
            state = {
                ...state
            }
        }
    }
    return state;
}

export default CategoryReducer