import React, { useEffect, useState } from 'react'
import AdminSidebar from '../../components/Admin/SideBar/AdminSidebar';
import AdminTopbar from '../../components/Admin/TopBar/AdminTopbar';
import { BiDownload, BiEdit, BiPlus, BiTrash } from 'react-icons/bi';
import Img from '../../assets/cat2.jpg';
// import { AdminAllCategory, AdminDeleteCategoryOrders } from '../../actions/Admin/AdminAction';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { adminDeleteBanner, adminGetBanner } from '../../actions/BannerAction';

const AdminBanner = () => {

  const navigate = useNavigate();
  const [banner, setBanner] = useState(null);
  const allbanner = useSelector(state => state.banner.banner);

  const authenticate = sessionStorage.getItem('admin_authenticate');
  useEffect(() => {
    if (!authenticate) {
      navigate('/');
    }
  }, [authenticate])

  const successToast = (msg) => {
    toast(msg, { position: 'top-center' });
  }

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(adminGetBanner());
  }, [dispatch])

  useEffect(() => {
    if (allbanner) {
      setBanner(allbanner);
    }
  }, [allbanner])


  const deleteBanner = (bid) => {
    dispatch(adminDeleteBanner(bid)).then(() => {
      dispatch(adminGetBanner());
    })
  }


  return (
    <>
      <div className='flex w-full h-screen'>
        <AdminSidebar name={'banner'} />
        <div className='flex flex-col w-full h-screen '>
          <AdminTopbar />
          <div className='flex flex-col pt-5 pl-5 pr-10'>
            <h1 className='font-dmsans text-lg font-semibold'>Banner</h1>
            <div className='w-full border rounded-lg mt-5 h-20 items-center flex pl-4 pt-4 pb-4 justify-end pr-5'>
              <Link to={'/admin-add-banner'}><button className='text-white h-11 hover:scale-110 transition-all duration-300 w-[200px] rounded-md flex justify-center items-center text-sm font-dmsans  bg-darkred'>Add Banner<span className='ml-2'><BiPlus size={15} /></span></button></Link>

            </div>
            <div className='flex-col mt-5'>

              <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" class="px-6 py-4">
                        text
                      </th>
                      <th scope="col" class="px-6 py-4">
                        color
                      </th>
                      <th scope="col" class="px-6 py-4">
                        tagline
                      </th>
                      <th scope="col" class="px-6 py-4">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className='text-black'>
                    {banner?.map((b,key)=>(
                    <tr key={key} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                      <td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {b?.text}
                      </td>
                      <td class="px-6 py-4">
                        <div className={`flex`}>
                          <div className={`h-5 w-5 bg-[${b?.color}] mr-2`}></div>
                          <div>{b?.color}</div>
                        </div>
                      </td>
                      <td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {b?.tagline}
                      </td>
                      <td class="px-4 py-4">
                        <div className='flex'>
                          <span className='mb-2 cursor-pointer hover:scale-110 duration-300 transition-all'
                            onClick={()=>{
                              successToast("Deleting Banner Please Wait...")
                              deleteBanner(b?._id);
                            }}
                          >
                            <BiTrash size={20} color='darkred' />
                          </span>
                        </div>
                      </td>
                    </tr>
                    ))}

                  </tbody>
                </table>
              </div>

            </div>
          </div>
        </div>
      </div >
      <ToastContainer />
    </>
  )
}

export default AdminBanner