import { adminAllCategoryConstant, adminAllCategoryParentConstant, adminAllOrdersConstant, adminAllProductConstant, adminCustomersConstants, adminGetUserInfoConstants, adminRecentOrderConstants, emailConstants, otpConstants, userLoginConstants } from "../../constant/constant";

const initState = {
    message: '',
    authenticate:false,
    admin:[]
};

const AdminReducer = (state = initState, action) => {

    switch (action.type) {
        case userLoginConstants.LOGIN_SUCCESS:
            state={
                ...state,
                admin:action.payload.user,
                authenticate:true,
            }
            break;
        default: {
            state = {
                ...state
            }
        }
    }
    return state;
}

export default AdminReducer