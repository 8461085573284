import { inboxConstants, inboxDeleteConstants } from "../constant/constant";

const initState = {
    message: '',
    enquiry: []
};

const InboxReducer = (state = initState, action) => {

    switch (action.type) {
        case inboxConstants.INBOX_SUCCESS:
            state = {
                ...state,
                enquiry: action.payload.enquiry,
            }
            break;
        case inboxDeleteConstants.INBOX_DELETE_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
            }
            break;
        default: {
            state = {
                ...state
            }
        }
    }
    return state;
}

export default InboxReducer