import { deleteCategoryConstants, getCategoryConstants } from "../constant/constant";
import axios from "../helpers/axios";


export const adminGetCategory = () => {
    return async (dispatch) => {
        dispatch({ type: getCategoryConstants.GET_CATEGORY_REQUEST });
        const res = await axios.post(`/admin-get-category`).catch((err) => {
            console.log(err.response)
            const { message } = err.response.data;
            dispatch({
                type: getCategoryConstants.GET_CATEGORY_FAILURE,
                payload: {
                    message,
                },
            });
        })
        if (res.status === 200) {
            const { category} = res.data;
            dispatch({
                type: getCategoryConstants.GET_CATEGORY_SUCCESS,
                payload: {
                    category,
                },
            });
        }
    };
};


export const adminDeleteCategory = (cid) => {
    return async (dispatch) => {
        dispatch({ type: deleteCategoryConstants.DELETE_CATEGORY_REQUEST });
        const res = await axios.post('/admin-remove-category', {
            cid
        })
        if (res.status === 200) {
            const { message } = res.data;
            dispatch({
                type: deleteCategoryConstants.DELETE_CATEGORY_SUCCESS,
                payload: {
                    message,
                },
            });
        }
    };
}

